import React from "react"
import "../css/custom.css"
import "@fontsource/poppins"
import "@fontsource/montserrat"
import { withTrans } from '../i18n/withTrans'

const Accordion = ({ children, t, i18n }) => {
  return (
    <>
      <div className="accordion accordion-flush" id="accordionFlushExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingOne">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOne"
              aria-expanded="false"
              aria-controls="flush-collapseOne"
            >
              {t('expander.expanderTitle1')}
            </button>
          </h2>
          <div
            id="flush-collapseOne"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingOne"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body">
              {t('expander.expanderContent1')}
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingTwo">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseTwo"
              aria-expanded="false"
              aria-controls="flush-collapseTwo"
            >
              {t('expander.expanderTitle2')}
            </button>
          </h2>
          <div
            id="flush-collapseTwo"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingTwo"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body">
              {t('expander.expanderContent2')}
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingThree">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseThree"
              aria-expanded="false"
              aria-controls="flush-collapseThree"
            >
              {t('expander.expanderTitle3')}
            </button>
          </h2>
          <div
            id="flush-collapseThree"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingThree"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body">
                {t('expander.expanderContent3')} 
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingFour">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseFour"
              aria-expanded="false"
              aria-controls="flush-collapseFour"
            >
              {t('expander.expanderTitle4')}
            </button>
          </h2>
          <div
            id="flush-collapseFour"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingFour"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body">
                {t('expander.expanderContent4')}
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingFive">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseFive"
              aria-expanded="false"
              aria-controls="flush-collapseFive"
            >
              {t('expander.expanderTitle5')}
            </button>
          </h2>
          <div
            id="flush-collapseFive"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingFive"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body">
                {t('expander.expanderContent5')}
            </div>
          </div>
        </div>
      </div>

      {/*<div class="accordion-item">
                      <div class="custom-switch col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2">
                        <div class="form-check form-switch " >
                          <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault"  data-bs-toggle="collapse" data-bs-target="#flush-PriceheadingOne" aria-expanded="false" aria-controls="flush-PriceOne"></input>
                        </div>
                      </div>


                      <div class="price_item">
                        <h2 class="" id="flush-headingOne">
                        </h2>
                        <div id="flush-PriceOne" class="accordion-collapse collapse" aria-labelledby="flush-PriceheadingOne" data-bs-parent="#accordionFlushExample">
                          <div class="accordion-body">Greitas ir patogus vizitų bei konsultacijų planavimas, valdymas tiesiogiai per pokalbių robotą.</div>
                        </div>
                      </div>
                    </div>*/}
    </>
  )
}
export default withTrans(Accordion)
