import React from "react"
import Layout from "../components/layout"
import Accordion from "../components/accordion"
import "../css/custom.css"
import "@fontsource/poppins"
import "@fontsource/montserrat"
import mockup_2 from "../css/bgImages/mockup_2.png"
import pokalbiuRobotaiKlinikomsMin from "../css/bgImages/pokalbiuRobotaiKlinikomsMin.jpg"
import stats from "../css/bgImages/stats.svg"
import clock from "../css/bgImages/clock.svg"
import info from "../css/bgImages/info.svg"
import padlock from "../css/bgImages/padlock.svg"
import chatbotgydytojugrafikaimin from "../css/bgImages/chatbotgydytojugrafikaimin.png"
import chatbotklinikomspaslaugosmin from "../css/bgImages/chatbotklinikomspaslaugosmin.jpg"
import chatbotklinikomsklausimuatsakymasmin from "../css/bgImages/chatbotklinikomsklausimuatsakymasmin.png"
import chatbotvizituvaldymasregistracijamin from "../css/bgImages/chatbotvizituvaldymasregistracijamin.png"
import screenshot_1_min from "../css/bgImages/screenshot_1_min.png"
import fb_case_min from "../css/bgImages/fb_case_min.png"
import viber_min from "../css/bgImages/viber_min.png"
import chatbotmedicinosistaigomsmin from "../css/bgImages/chatbotmedicinosistaigomsmin.jpg"
import { withTrans } from '../i18n/withTrans'



const Home = ({ children, t, i18n }) => {
  return (
    <>
    <div className="img_bg">
      
        <header className="landing_header" id="pradzia">
          <div className="row">
            <div className="header_style col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 ">
              <h1>
                <b>{t('home.boldHeaderText')} </b>
                <span className="font_ligter">
                  {t('home.headerText')} 
                </span>{" "}
              </h1>
              <p>
                {" "}
                {t('home.postHeaderText')} {" "}
              </p>
              <button className="button_green">{t('home.headerButton')}</button>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <img src={mockup_2} alt="mockup_1" />
            </div>
          </div>
        </header>

        <section className="solutions row_gap">
          <h1>{t('home.headingSolutions')}</h1>
          <br></br>
          {/*<div class="row">
                <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <h3> Ligoninėms </h3>
                    
                </div>  
                <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <h3> Ligoninėms </h3>
                </div>  
                <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <h3> Ligoninėms </h3>
                </div>   
            </div>
            <div class="row">
                <div class="d-none d-md-block col-xxl-2 col-xl-2 col-lg-2"></div>
                <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                    <h3> Ligoninėms </h3>
                </div>  
                <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                    <h3> Ligoninėms </h3>
                </div> 
                <div class="d-none d-md-block col-xxl-2 col-xl-2 col-lg-2"></div>
</div>*/}
          <div className="collapse_side section_sprendimams">
            <div className="row reverse_css">
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 img-align">
                <img src={chatbotmedicinosistaigomsmin} alt="img_1" />
              </div>
              <div className="content_align col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                <ul className="check_list ">
                  <li>{t('home.solutionList1')}</li>
                  <li>{t('home.solutionList2')}</li>
                  <li>{t('home.solutionList3')}</li>
                  <li>{t('home.solutionList4')}</li>
                  <li>{t('home.solutionList5')}</li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="collapse_side">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <Accordion></Accordion>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 img-align">
              <img src={pokalbiuRobotaiKlinikomsMin} alt="img_1" />
            </div>
          </div>
        </section>

        <section className="blue_boxes" id="nauda">
          <h1>{t('home.headingBenefits')}</h1>
          <p>
            {t('home.postHeadingBenefits')}
          </p>
          <div className="solutions white_col">
            <div className="row">
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 px-4">
                <div className="blue_box row">
                  <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-12 col-xs-12 d-flex  justify-content-center">
                    <img src={clock} alt="icon_1" />
                  </div>
                  <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-10 col-sm-12 col-xs-12">
                    <h4>{t('home.benefitsHeading1')}</h4>
                    <p>
                      {t('home.benefitsContent1')}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 px-4">
                <div className="blue_box row">
                  <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-12 col-xs-12 d-flex justify-content-center">
                    <img src={stats} alt="icon_2" />
                  </div>
                  <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-10 col-sm-12 col-xs-12">
                    <h4>{t('home.benefitsHeading2')}</h4>
                    <p>
                     {t('home.benefitsContent2')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 px-4">
                <div className="blue_box row">
                  <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-12 col-xs-12 d-flex justify-content-center">
                    <img src={info} alt="icon_3" />
                  </div>
                  <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-10 col-sm-12 col-xs-12">
                    <h4>{t('home.benefitsHeading3')}</h4>
                    <p>
                      {t('home.benefitsContent3')}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 px-4">
                <div className="blue_box row">
                  <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-12 col-xs-12 d-flex justify-content-center">
                    <img src={padlock} alt="icon_4" />
                  </div>
                  <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-10 col-sm-12 col-xs-12">
                    <h4>{t('home.benefitsHeading4')}</h4>
                    <p>
                      {t('home.benefitsContent4')}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <br></br>
          </div>
          <button className="button_blue">{t('home.headerButton')}</button>
        </section>

        <section className="bg_green" id="funkcijos">
          <div className="width_function">
            <h1>{t('home.functionality')}</h1>
            <div className="function_grid">
              <div className="row">
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <h4>{t('home.functionsHeading1')}</h4>
                  <p>
                    {t('home.functionsContent1')}
                  </p>
                </div>
                <div className="imgBox col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 d-flex justify-content-center">
                  <img
                    src={chatbotvizituvaldymasregistracijamin}
                    alt="mockup_2"
                    width="500"
                    height="auto"
                  />
                </div>
              </div>
              <div className="row d-flex flex-xxl-row flex-xl-row flex-lg-row flex-xxl-row flex-md-row  flex-sm-column-reverse flex-xs-column-reverse reverse_css">
                <div className="imgBox col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 d-flex justify-content-center">
                  <img
                    src={chatbotgydytojugrafikaimin}
                    alt="mockup_3"
                    width="500"
                    height="auto"
                  />
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <h4>{t('home.functionsHeading2')}</h4>
                  <p>
                    {t('home.functionsContent2')}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <h4>{t('home.functionsHeading3')}</h4>
                  <p>
                    {t('home.functionsContent3')}
                  </p>
                </div>
                <div className="imgBox col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 d-flex justify-content-center">
                  <img
                    src={chatbotklinikomspaslaugosmin}
                    alt="mockup_4"
                    width="500"
                    height="auto"
                  />
                </div>
              </div>
              <div className="row d-flex flex-xxl-row flex-xl-row flex-lg-row flex-xxl-row flex-md-row flex-sm-column-reverse flex-xs-column-reverse reverse_css">
                <div className="imgBox col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 d-flex justify-content-center">
                  <img
                    src={chatbotklinikomsklausimuatsakymasmin}
                    alt="mockup_5"
                    width="500"
                    height="auto"
                  />
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <h4>{t('home.functionsHeading4')}</h4>
                  <p>
                    {t('home.functionsContent4')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="social_bg" id="kanalai">
          <div className="width_function">
            <h1>{t('home.Channels')}</h1>
            <div className="social_m row">
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4">
                <img src={screenshot_1_min} alt="social_1" />
                <h4>{t('home.Channels1')}</h4>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4">
                <img src={fb_case_min} alt="social_2" />
                <h4>{t('home.Channels2')}</h4>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4">
                <img src={viber_min} alt="social_3" />
                <h4>Viber</h4>
              </div>
            </div>
          </div>
        </section>
        
      </div>
    </>
  )
}




export default withTrans(Home)